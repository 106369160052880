<template>
    <div class="card ">
        <div class="card-header border-0">
            <h3 class="card-title">
                <i class="fas fa-th mr-1"></i>
                Llamadas
            </h3>
            <i class="fas fa-info" style="float:right" @click="toggle($event)"></i>

        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <Chart type="doughnut" :data="chartData" :options="lightOptions" />
                </div>
                <div class="col-md-8">
                    <usuario-resumen-estadisticas-llamadas-graficas />
                </div>
            </div>
            <OverlayPanel ref="info3">
                <h4 style="text-align:center">Llamadas</h4>
                <p><b>Minutos llamadas emitidas:</b>Minutos de conversación en llamadas emitidas<br>
                    <b>Minutos llamadas recibidas:</b>Minutos de conversación en llamadas recibidas<br>
                    <b>Espera no atendido:</b>Minutos en espera de llamadas no atendidas<br>
                    <b>Llamadas emitidas:</b> Nº de llamadas emitidas<br>
                    <b>Recibidas contestadas:</b> Nº de llamadas que se han recibido y se han contestado.
                </p>

            </OverlayPanel>
        </div>
        <!-- /.card-body -->

    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import UsuarioResumenEstadisticasLlamadasGraficas from './UsuarioResumenEstadisticasLlamadasGrafica.vue';
import OverlayPanel from 'primevue/overlaypanel';

export default {
    
    data() {
        return {
            estadisticas:[],
            chartData: {
                labels: ['Consumido','Espera NO ATENDIDO','Espera ATENDIDO'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#FF6384","#36A2EB","#FFCE56"],
                        hoverBackgroundColor: ["#FF6384","#36A2EB","#FFCE56"]
                    }
                ]
            },
			lightOptions: {
				plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        text: "Minutos",
                        display: true,
                    }
                },
			}
        }
    },
    components: {
        'usuario-resumen-estadisticas-llamadas-graficas': UsuarioResumenEstadisticasLlamadasGraficas,
        OverlayPanel
    },
    methods: {

        async cargardatos() {
            const api = new PwgsApi();
            this.estadisticas = await api.get('estadisticas');
            this.chartData.datasets[0].data[0] = this.estadisticas.graficas.centralita.minutos_emitidos_hablados;
            this.chartData.datasets[0].data[1] = this.estadisticas.graficas.centralita.minutos_recibidos_hablados;
            this.chartData.datasets[0].data[2] = this.estadisticas.graficas.centralita.minutos_espera_no_contestado;



        },
        toggle(event) {
            this.$refs.info3.toggle(event);
        },

    },
    watch: {

    },
    activated() {
        this.cargardatos();
    }
}
</script>
