<template>
    <div class="row">
        <div class="col-md-7">
            <usuario-resumen-estadisticas-siniestros :datosestadisticas="datosestadisticas" />
            <div class="row">
                <div class="col-md-6">
                    <usuario-resumen-estadisticas-facturacion :datosestadisticas="datosestadisticas" />
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-chart-pie mr-1"></i>
                                Alertas de visitas
                            </h3>
                        </div>
                        <div class="card-body">                            
                            <div class="chart" style="position: relative; ">
                                
                                <a @click="cargarExpedienteAvisos(aviso.tipo_servicio)" href="#" class="dropdown-item" v-for="aviso in avisosVisitas" :key="aviso.id_aviso" :style="aviso.style"  >
                                    <div data-toggle="tooltip" data-placement="top"  >
                                        <i :class="aviso.class" :title="aviso.texto"></i> {{aviso.titulo}}    
                                        <span class="badge bg-danger float-right" v-if="aviso.numero" >{{aviso.numero_alertas}}</span>   
                                        <span class="badge float-right" v-if="aviso.numero">&nbsp;&nbsp;</span>
                                        <span class="badge bg-warning float-right" v-if="aviso.numero">{{aviso.numero_avisos}}</span>                
                                        <span class="float-right text-muted " v-else>{{aviso.texto}}</span>                
                                    </div>
                                </a> 
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <usuario-resumen-estadisticas-acciones :datosestadisticas="datosestadisticas" />
        </div>
        <div class="col-md-5">
            <usuario-resumen-estadisticas-llamadas :datosestadisticas="datosestadisticas" />
            <div class="row">

                <div class="col-md-6">
                    <usuario-resumen-estadisticas-incidencias :datosestadisticas="datosestadisticas" />                
                </div>
                <div class="col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="far fa-smile mr-1"></i>
                                    Satisfacción
                                </h3>
                            </div>
                            <div class="card-body">                            
                                <div class="chart" style="position: relative; ">
                                    <a @click="cargarExpedienteAvisos()" href="#" class="dropdown-item" v-for="satis in satisfacciones" :key="satis.valor">
                                        <div data-toggle="tooltip" data-placement="top"  >
                                            <i :class="satis.class"></i> {{satis.titulo}}    
                                            <span class="badge bg-danger float-right">{{satis.total}}</span>     
                                        </div>
                                    </a> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            <usuario-resumen-estadisticas-flota/>
        </div>
    </div>
</template> 
<script>
import UsuarioResumenEstadisticasFlota from './secciones/UsuarioResumenEstadisticasFlota.vue';
import UsuarioResumenEstadisticasSiniestros from './secciones/UsuarioResumenEstadisticasSiniestros.vue';
import UsuarioResumenEstadisticasFacturacion from './secciones/UsuarioResumenEstadisticasFacturacion.vue';
import UsuarioResumenEstadisticasLlamadas from './secciones/UsuarioResumenEstadisticasLlamadas.vue';
import UsuarioResumenEstadisticasIncidencias from './secciones/UsuarioResumenEstadisticasIncidencias.vue';
import UsuarioResumenEstadisticasAcciones from './secciones/UsuarioResumenEstadisticasAcciones.vue';
import { PwgsApi } from '../../../../services/PwgsApi'
export default ({
    data() {
     return {
        datosestadisticas: [],
        avisosVisitas:[],
        satisfacciones: [],
    }  
    },
    components: {
        'usuario-resumen-estadisticas-siniestros': UsuarioResumenEstadisticasSiniestros,
        'usuario-resumen-estadisticas-facturacion': UsuarioResumenEstadisticasFacturacion,
        'usuario-resumen-estadisticas-llamadas': UsuarioResumenEstadisticasLlamadas,
        'usuario-resumen-estadisticas-incidencias': UsuarioResumenEstadisticasIncidencias,
        'usuario-resumen-estadisticas-acciones': UsuarioResumenEstadisticasAcciones,
        'usuario-resumen-estadisticas-flota':UsuarioResumenEstadisticasFlota,
    },
    methods: {
        cargarExpedienteAvisos: function(tipo){
            //alert(tipo);
            console.log("tipo", tipo);
            localStorage.setItem("tipobuscador", tipo);
            this.$router.push({ name: 'Escritorio', params: { tipo_buscador: tipo } })
            
           // this.$emit('evento_expediente_avisos_tipo', tipo); //Es llamado a ./Escritorio.vue
        },
       async cargarestadisticas() {
            const api = new PwgsApi;
            this.datosestadisticas = await api.get('estadisticas');
            const rr  = await api.post('avisos');
            const avisos = rr.datos;
            const st = await api.post('avisos/satisfaccion');            
            for(let stf of st){
                var nn = '';
                console.log('satisf', stf);
                if(stf.satisfaccion_ia_servicio == 0){
                    nn = {titulo: '0: Neutro', class: " fas fa-angle-right ml-2 mr-1", valor: 0, total:stf.total};
                    this.satisfacciones.push(nn);
                }
                if(stf.satisfaccion_ia_servicio == 1){
                    nn = {titulo: '1: Muy insatisfecho', class: " fas fa-angle-right ml-2 mr-1", valor:1, total:stf.total};
                    this.satisfacciones.push(nn);
                }
                if(stf.satisfaccion_ia_servicio == 2){
                    nn = {titulo: '2: Poco satisfecho', class: " fas fa-angle-right ml-2 mr-1", valor:2, total:stf.total};
                    this.satisfacciones.push(nn);
                }
                if(stf.satisfaccion_ia_servicio == 3){
                    nn = {titulo: '3: Satisfecho', class: " fas fa-angle-right ml-2 mr-1", valor:3, total:stf.total};
                    this.satisfacciones.push(nn);
                }
                if(stf.satisfaccion_ia_servicio == 4){
                    nn = {titulo: '4: Muy satisfecho', class: " fas fa-angle-right ml-2 mr-1",valor:4, total:stf.total};
                    this.satisfacciones.push(nn);
                }
            }

            console.log('avisosVisitas',avisos)
            for(let aviso of avisos){
                console.log('avisoss',aviso);
                if(aviso.tipo_servicio && aviso.tipo_servicio=="visitas_Noendomicilio" || aviso.tipo_servicio=="visitas_Nofinalizadas" || 
                    aviso.tipo_servicio=="visitas_Sinconfirmar" || aviso.tipo_servicio=="visitas_Sinfirma" || aviso.tipo_servicio=="visitas_Sinfotos"){
                    this.avisosVisitas.push(aviso);
                }
            }
            console.log('avisosVisitas',this.avisosVisitas)
    },
        
    },
    activated() {
        this.cargarestadisticas();
    }
})
</script>
