<template>
    <!-- solid sales graph -->
    <div class="card ">
        <div class="card-header border-0">
            <h3 class="card-title">
                <i class="fas fa-th mr-1"></i>
                Incidencias
            </h3>
            <i class="fas fa-info" style="float:right" @click="toggle($event)"></i>

        </div>
        <div class="card-body">
            <Chart type="line" :data="basicData" :options="basicOptions" />
            <OverlayPanel ref="info5">
                <h4 style="text-align:center">Incidencias</h4>
                <p>Número de incidencias diarias.
                <ul>
                    <li><strong>Roja:</strong> Incidencias imputadas a tramitador/a</li>
                    <li><strong>Amarilla:</strong> Incidencias en expedientes (independientemente a quien se imputan)</li>
                </ul>
                </p>

            </OverlayPanel>
        </div>
        <!-- /.card-body -->

        <!-- /.card-footer -->
    </div>
    <!-- /.card -->
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import OverlayPanel from 'primevue/overlaypanel';


export default {
    components: {

        OverlayPanel,
    },
    data() {
        return {
            basicData: {
                labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
                datasets: [
                    {
                        label: 'Tramitación',
                        data: [],
                        fill: false,
                        borderColor: '#EE4D4D',
                        tension: .4
                    },
                    {
                        label: 'Expediente',
                        data: [],
                        fill: false,
                        borderColor: '#EEB84D',
                        tension: .4
                    }
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },
     methods: {

        async cargardatos() {
             const api = new PwgsApi();
            this.estadisticas = await api.get('estadisticas');
             this.basicData.labels = this.estadisticas.graficas.incidencias.labels;
             this.basicData.datasets[0].data = this.estadisticas.graficas.incidencias.incidencias;
             this.basicData.datasets[1].data = this.estadisticas.graficas.incidencias.incidencias_expediente;

         },
         toggle(event) {
             this.$refs.info5.toggle(event);
         },

    },
    watch: {

    },
    activated() {
        this.cargardatos();
    }
}

</script>