<template>
    <!-- solid sales graph -->
    <div class="card bg-gradient-info">
        <div class="card-header border-0">
            <h3 class="card-title">
                <i class="fas fa-th mr-1"></i>
                Facturación
            </h3>
            <i class="fas fa-info" style="float:right" @click="toggle($event)"></i>

        </div>
        <div class="card-body">
            <Chart type="line" :data="basicData" :options="basicOptions" />
            <OverlayPanel ref="info4">
                <h4 style="text-align:center">Facturación</h4>
                <p>Indica el total de facturas/albaranes realizados por día<br>
                <ul>
                    <li>No tiene en cuenta la fecha de la factura/albarán,<br>
                        sino la <strong>fecha/hora de creación</strong> de la misma.</li>
                    <li>No tiene en cuenta la eliminación de facturas</li>
                </ul>
                </p>
            </OverlayPanel>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';
import OverlayPanel from 'primevue/overlaypanel';

export default {
    components: {

        OverlayPanel,
    },
    data() {
        return {
            estadisticas:[],
            basicData: {
                labels: [],
                datasets: [
                    {
                        label: 'Base imponible',
                        data: [],
                        fill: false,
                        borderColor: '#FFFFFF',
                        tension: .4
                    }
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#FFFFFF'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#FFFFFF'
                        },
                        grid: {
                            color: '#FFFFFF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#FFFFFF'
                        },
                        grid: {
                            color: '#FFFFFF'
                        }
                    }
                }
            }
        }
    },
    methods: {
            
       async cargardatos() {
            const api = new PwgsApi
            this.estadisticas = await api.get('estadisticas');
            this.basicData.labels = this.estadisticas.graficas.facturacion.labels;
            this.basicData.datasets[0].data = this.estadisticas.graficas.facturacion.facturacion;
      
          

        },
        toggle(event) {
            this.$refs.info4.toggle(event);
        },


    },
    watch: {
        
    },
       activated() {
           this.cargardatos();
    }

}
</script>