<template>
    <Chart type="line" :data="basicData" :options="basicOptions" />
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';


export default {
    data() {
        return {
            estadisticas:[],
            basicData: {
                labels: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
                datasets: [
                    {
                        label: 'Contestadas',
                        data: [],
                        fill: false,
                        borderColor: '#42A5F5',
                        tension: .4
                    },
                    {
                        label: 'No contestadas',
                        data: [],
                        fill: false,
                        borderColor: '#FFA726',
                        tension: .4
                    },
                    {
                        label: 'Emitidas',
                        data: [],
                        fill: false,
                        borderColor: '#CB1010',
                        tension: .4
                    }
                ]
            },
            basicOptions: {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    }
                }
            }
        }
    },
     methods: {

        async cargardatos() {
             const api = new PwgsApi();
            this.estadisticas = await api.get('estadisticas');
            this.basicData.labels = this.estadisticas.graficas.centralita.labels;
            this.basicData.datasets[0].data = this.estadisticas.graficas.centralita.contestadas;
            this.basicData.datasets[1].data = this.estadisticas.graficas.centralita.no_contestadas;
            this.basicData.datasets[2].data = this.estadisticas.graficas.centralita.emitidas;
        },

    },
    watch: {

    },
    activated() {
        this.cargardatos();
    }

}

</script>